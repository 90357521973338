/* LATO */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../../assets/fonts/Lato-Regular.ttf');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../../assets/fonts/Lato-Regular.ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../../../assets/fonts/Lato-Bold.ttf');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../../../assets/fonts/Lato-Bold.ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/*
 * GENERAL
 */
html {
    height: 100%;
}

#tableRows{
    text-transform: uppercase;
}

body {
    font-family: 'Lato', sans-serif;
    position: relative;
    color: #727980;
}

h1, h2, h3, h4, h5, h6 {
    color: #3b4b60;
}

.div-canvas{
    max-width: 320px;
    max-height: 320px;
    min-width: 320px;
    min-height: 320px;
}

/* recaptcha */
.g-recaptcha{
    transform:scale(0.95);
    transform-origin:0 0;
    margin-bottom: 10px;
}

/* centered columns styles */
.row-centered {
    text-align: center;
}

.col-centered {
    display: inline-block;
    float: none;
    /* reset the text-align */
    text-align: left;
    /* inline-block space fix */
    margin-right: -4px;
    height: 6px;
}

/*
 * SVG
 */
#svg-conta {
    width: 20px;
    height: 20px;
}

a:focus {
    outline: 0;
}

.container-fluid {
    padding-left: 250px;
    padding-right: 0px;
}

.card-block {
    height: 70px;
    margin-top: 20px;
    padding-bottom: 7px;
    border: 1px solid #e7e7e7;
}

.card-info {
    margin-top: 20px;
    border: 1px solid #e7e7e7;
    padding: 20px;
    padding-bottom: 30px;
}

.card-info button, .card-block button {
    width: 100%;
    background: none;
    border: none;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 20px;
}

.card-block button, .card-block .btn {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    margin-top: 0;
}

.card-block .svg-seta {
    width: 50px;
}

.svg-seta {
	width: 50px;
	height: 42px;
    transform: rotate(270deg);
}

.card-info button i, .card-block button i {
    float: left;
}

.card-info .card-title {
    font-weight: 700;
    font-size: 18px;
    color: #727980;
}

.card-info img, .card-block img {
    float: left;
    max-width: 70px;
    margin-right: 30px;
    margin-bottom: 20px;
    border: 1px solid #e7e7e7;
}

.card-block img {
    border: none;
    margin-right: 10px;
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
}


.card-block img .card-status:after{
    float:none;
    position: absolute;
}

.card-block svg {
    width: 80px;
    height: 60px;
    float: left;
}

.card .card-image .card-status svg {
    height: 114px;
    max-width: 100%;
}

[data-notify="container"] {
    max-width: 250px;
    background-image: none;
    background-color: #555;
    border-color: rgba(149, 149, 149, 0.3);
    border-radius: 3px;
    color: #f8f8f8;
    padding: 10px;

}

[data-notify="container"].alert-success {
    border-left: 4px solid #65C400;
}

[data-notify="container"].alert-danger {
    border-left: 4px solid #990000;
}

[data-notify="container"] .close {
    color: #fff;
}

i.loading {
    padding: 20px;
    font-size: 30px;
}

.btn i.loading {
    padding: 0;
    margin-left: 10px;
    font-size: 16px;
}

.alert ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.text-danger {
	color: #e03f3f !important;
}

.text-warning{
	color: #3b4b60 !important;
}

.text-success {
    color: #609e27 !important;
}

.text-danger svg path {
    fill: #e03f3f;
}

.text-bordered {
    padding: 100px 20px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    margin: 20px 0;
    background-color: #f7f7f7;
}

.div-bordered {
    border: 1px solid #e7e7e7;
    border-radius: 10px;
}

.margin-top {
    margin-top: 20px;
}

.margin-right {
    margin-right: 10px !important;
}

.margin-left {
    margin-left: 10px !important;
}

.padding {
    padding: 15px;
}
.padding-top {
    padding-top: 15px;
}
.margin {
    margin: 15px;
}
.no-padding {
    padding: 0 !important;
}
.no-margin {
    margin: 0 !important;
}
.toggle-div .caret{
    margin-left: 5px;
}
.form-control-static {
    padding: 10px;
}

.card-block-status {
    margin-top: 18px;
}

.card-block span {
    display: block;
}

.card-info span {
    display: block;
}

/*
 * FORM
 */
#form-transfer input[type="text"], #form-transfer select {
    height: 45px;
    text-align: left;
    margin-bottom: 10px;
}

#form-transfer input[type="radio"] {
    margin-bottom: 17px;
}

.radio-document{
	line-height: 24px;
	padding-top: 4px !important;
}

.form-horizontal .control-label {
    font-size: 12px;
    text-transform: uppercase;
    padding: 0;
    min-height: 30px;
    padding-top: 15px;
    color: #727980;
}

.form-horizontal .twolines {
    padding-top: 7px;
}

.form-horizontal .form-control {
    margin-bottom: 9px;
}

.form-control {
    border: 1px solid #e7e7e7;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding-left: 20px;
    height: 45px;
    color: #727980;
}

.radio label, .checkbox label {
    font-size: 12px;
}

select {
    background: transparent;
    border: 1px solid #ccc;
    height: 34px;
    padding-right: 20px;
}

select:before {
    position: absolute;
    font-family: FontAwesome;
    top: 7px;
    right: 10px;
    font-size: 18px;
    content: "\f13a";
}

.form-radio {
    margin-right: 10px;
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
    margin-top: 7px;
}

.features-checkboxes .control-label {
    padding-top: 0
}

/*
 * BUTTONS
 */
.btn-default {
    background: #fff;
    border: 1px solid #e7e7e7;
    font-family: 'Lato', sans-serif;
    color: #727980;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 12px;
}

.btn-default:hover, 
.btn-default:focus, 
.btn-default.focus, 
.btn-default:active, 
.btn-default.active {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(59, 75, 96, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(59, 75, 96, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(59, 75, 96, 0.15);
    background: #fff;
    border-color: #caced5;
}

.btn-success, .btn-info {
    font-family: 'Lato', sans-serif;
    color: #fff;
    text-transform: uppercase;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 12px;
}

.btn-success:hover, 
.btn-success:focus, 
.btn-success.focus, 
.btn-success:active, 
.btn-success.active {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    filter: alpha(opacity=90);
    -moz-opacity: 0.9;
    -khtml-opacity: 0.9;
    opacity: 0.9;
}

.btn-transfer{
    margin-bottom: 20px !important;
}

/*
 * MODAL
 */
 
.modal-backdrop {
    z-index: 1041;
    position: fixed;
}

.modal-dialog{
	z-index: 1042;
}
   
.modal-content {
    border-radius: 0;
}

.modal-header {
    text-transform: uppercase;
    padding: 20px;
    background-color: #FBFBFB;
}

.modal-body {
    padding: 0;
    padding-bottom: 20px;
	width: 100%;
    background: #ffffff;
    display: table;
}

.modal-body .tab-content{
	background: #ffffff;
}

.modal-header .close {
    font-size: 20px;
    opacity: 0.5;
    padding: 5px 0 10px 0;
}

.modal-header .modal-title {
    margin-top: 6px;
}

.modal-basic .modal-body,
#ModalCarga .modal-body,
#ModalContestations .modal-body {
    padding: 30px;
}

.modal-basic .panel,
#ModalCarga .panel,
#ModalContestations .panel {
    margin-top: 25px;
}

.modal-basic .panel-default,
#ModalCarga .panel-default {
    background-color: #FBFBFB;
}

.modal-basic .panel-default > .panel-heading,
#ModalCarga .panel-default > .panel-heading,
#ModalContestations .panel-default > .panel-heading {
    background-image: none;
}

.modal-basic .color,
#ModalCarga .color,
#ModalContestations .color {
    font-weight: bold;
}

.modal-basic .form-horizontal .form-group{
	margin-left: 0;
	margin-right: 0;
}

.account-success {
    text-align: center;
}

.charge-link {
    margin: 15px 0;
}

.chart{
    top: 50px;
    padding-left: 25%;
    padding-right: 25%;
    position: relative;
}

#paymentMethods .paymentMethod, #paymentMethodsRecurrence .paymentMethod{
    border: 1px solid #ddd;
    cursor: pointer;
    -webkit-transition: all 200ms ease-out 0s;
    -moz-transition: all 200ms ease-out 0s;
    -o-transition: all 200ms ease-out 0s;
    transition: all 200ms ease-out 0s;
    display: inline-block;
    height: 100%;
    max-height: 35px;
    margin-right: 5px;
}
#paymentMethods .paymentMethod:hover,
#paymentMethods .paymentMethod:focus,
#paymentMethods .paymentMethod:active, 
#paymentMethods .paymentMethod.active
#paymentMethodsRecurrence .paymentMethod:hover,
#paymentMethodsRecurrence .paymentMethod:focus,
#paymentMethodsRecurrence .paymentMethod:active, 
#paymentMethodsRecurrence .paymentMethod.active{
    border-bottom-color: #009926;
    border-bottom-width: 4px;
}

#paymentMethods .radio-inline,
#paymentMethodsRecurrence .radio-inline{
    border: 1px solid #e7e7e7;
    box-shadow: none;
    height: 45px;
    color: #727980;
    margin-left: 0;
    height: 70px;
    width: 100%;
}

#paymentMethods .radio-inline input,
#paymentMethodsRecurrence .radio-inline input{
    display:inline;
    height: 16px;
}

.tab-content h3 {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 400;
}

.tab-content .btn-success, .btn-info {
    min-width: 160px;
}

#ModalAddCard #addcard-menu,
#ModalPreferences #preference-menu,
#ModalPreferencesCard #preference-card-menu {
    float: left;
    width: 30%;
}

#ModalAddCard .tab-content,
#ModalPreferences .tab-content,
#ModalPreferencesCard .tab-content {
    float: right;
    width: 70%;
    background: #fff;
    border-left: 1px solid #e7e7e7;
    padding: 30px;
    padding-left: 30px;
    min-height: 700px;
}

#ModalAddCard .nav-tabs,
#ModalPreferences .nav-tabs,
#ModalPreferencesCard .nav-tabs {
    margin-top: 30px;
    border: none;
}

#ModalAddCard .nav-tabs > li > a,
#ModalPreferences .nav-tabs > li > a,
#ModalPreferencesCard .nav-tabs > li > a {
    border-radius: 0;
    text-transform: uppercase;
    font-size: 12px;
    border: none;
    border-left: 3px solid transparent;
    color: #727980;
}

#ModalAddCard .nav-tabs > li.active > a,
#ModalAddCard .nav-tabs > li.active > a:hover,
#ModalAddCard .nav-tabs > li.active > a:focus,
#ModalAddCard .nav-tabs > li > a:hover,
#ModalPreferences .nav-tabs > li.active > a,
#ModalPreferences .nav-tabs > li.active > a:hover,
#ModalPreferences .nav-tabs > li.active > a:focus,
#ModalPreferences .nav-tabs > li > a:hover,
#ModalPreferencesCard .nav-tabs > li.active > a, 
#ModalPreferencesCard .nav-tabs > li.active > a:hover, 
#ModalPreferencesCard .nav-tabs > li.active > a:focus, 
#ModalPreferencesCard .nav-tabs > li > a:hover {
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-left: 3px solid #0088cb;
    color: #0088cb;
}

.tooltip-box {
    padding-right: 10px;
}

/*
 *TOOLTIP
 */
.tooltip > .tooltip-inner {
    background-color: #828B9C;
    font-weight: bold;
    padding: 8px 15px;
    line-height: 9px;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #828B9C;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #828B9C;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #828B9C;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #828B9C;
}

/*
 * NAVBAR
 */
.nav-select {
    display: none;
}


.navbar-toggle {
    float: left;
}

.navbar-brand {
    padding: 10px 0 0 25px;
}

.navbar-brand > img {
    max-height: 35px;
}

.navbar-toggle-left {
    float: left !important;
}

.navbar-nav > li {
    width: 40px;
}

.navbar-nav > li > a {
    color: #fff;
}

.navbar-nav > li > a {
    height: 30px;
    margin: 10px;
    padding: 5px;
    min-width: 40px;
    font-size: 1.3em;
    text-align: center;
}

.nav > li > a:hover, .nav > li > a:focus {
    background: none;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background: none !important;
}

#header_help_bar i {
    font-size: 20px;
    width: 20px;
}

.dropdown-notification a i {
    font-size: 20px;
}

.dropdown-user {
    width: 129px !important;
    text-align: left;
    margin: 10px 30px 0 30px;
    padding: 0;
    height: 30px;
}

.dropdown-user .dropdown-toggle {
    margin: 0;
    padding: 0;
    height: 30px;
}

.dropdown-user .dropdown-toggle i {
    float: right;
    margin-top: 5px;
    margin-left: 10px;
}

.avatar svg {
    width: 64px;
    height: 45px;
    margin-right: 0px;
    margin-left: -22px;
    margin-top: -7px;
    float: left;
}

.svg {
    fill: #727980;
}

.dropdown-user .username {
    font-size: 14px;
    display: inline-block;
    margin-top: 3px;
    float: left;
    width: 65px;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.notifications {
    width: 280px;
    top: 45px;
    margin-left: -110px;
    list-style: none;
    color: #555;
}

.notifications li:first-child {
    margin-top: -20px;
}

.dropdown-menu {
    border: 1px solid #e7e7e7;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.176);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.176);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.176);
}

.dropdown-menu:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}

.dropdown-menu:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
}

.datepicker:after, .datepicker:before {
    display: none !important;
}

.menu-flutuante-dropdown:before {
    right: 50%;
    margin-right: -7px;
}

.menu-flutuante-dropdown:after {
    right: 50%;
    margin-right: -6px;
}

.profile-menu:before {
    left: 90%;
}

.modal-content .notifications {
    width: 100%;
    margin: 0;
    padding: 0;
}

.modal-content .notification-text {
    width: 80%;
    padding-top: 35px;
}

.modal-content .notification-icon {
    padding-top: 35px;
    width: 20%;
    font-size: 20px;
}

.modal-content .notification-time {
    padding-top: 20px;
    padding-bottom: 5px;
}

#notification-count {
    position: absolute;
    top: 0px;
    left: 20px;
    font-size: 10px;
    background: #fff;
}

#notification-count-links {
    float: right;
    font-size: 12px;
    padding-top: 5px;
    width: 23px;
    height: 23px;
    background: #DD3F40;
    color: #fff;
}

.notification-item {
    border-bottom: 1px solid #ddd;
}

.notification-body {
    margin: 5px;
}

.notification-body:hover, 
.notification-link:hover {
    background-color: #F8F8F8;
}

.notification-item:last-child {
    border: none;
}

.notification-icon {
    width: 55px;
    float: left;
    padding: 20px 20px 20px 15px;
    text-align: center;
}

.notification-text {
    padding: 20px 15px 20px 20px;
    padding-left: 0;
    width: 210px;
    float: right;
    font-size: 14px;
}

.notification-text > p {
    margin: 0;
    padding: 0;
}

.notification-time {
    padding-top: 10px;
    color: #c0c4cd;
}

.notification-link {
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    margin: 5px;
}

.notification-link a {
    text-decoration: none;
    color: #555;
}

.navbar-nav > li > .dropdown-menu {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.icon-bar {
    background-color: #fff;
}

#profile-button {
    cursor: pointer;
    width: 60px;
}

#profile-button svg {
    height: 46px;
}

#profile-button svg path {
    fill: #fff;
}

.profile-menu {
    width: 300px;
    margin-right: 15px;
    top: 35px;
    height: 130px;
    padding-top: 15px;
    z-index: 1200;
    color: #555;
}

.profile-menu .clearfix {
    margin-top: -20px;
}

.last-access {
    font-size: 12px;
    color: #727980;
}

.profile-avatar svg {
	width: 85px;
    height: 60px;
    margin-top: 20px;
}

.profile-avatar > .avatar {
    width: 50px;
    height: 50px;
}

.profile-text, .profile-buttons {
    width: 200px;
    padding: 25px 0 10px 0;
}

.profile-buttons {
    margin-top: 6px !important;
}

.profile-name {
    font-size: 18px;
    height: 25px;
    overflow: hidden;
    color: #727980;

}

.profile-buttons {
    margin-top: 0px;
    padding: 0 20px 20px 0;
}

.profile-buttons > a:first-child {
    margin: 0 10px 0 0;
}

.profile-links {
    clear: both;
}

.profile-links a {
    display: block;
    padding: 10px 20px;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #FBFBFB;
    color: #808E9B;
}

.password-tooltip{
	line-height: 42px;
}

/*
 * SIDEBAR
 */
.page-sidebar {
    width: 250px;
    height: 100%;
    position: absolute;
    top: 50px;
    bottom: 0;
    margin-bottom: 20px;
    z-index: 1000;
    position: fixed;
    overflow: scroll;
}

.page-sidebar::-webkit-scrollbar {
    display: none;
}

.page-sidebar ul {
    list-style: none;
    margin: 0;
    padding: 25px 0;
    margin-bottom: 20px;
}

.page-sidebar a {
    height: 50px;
    padding: 15px 30px;
    display: block;
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
}

.page-sidebar .sub-menu a {
    padding: 15px 5px 15px 75px !important;
}

.page-sidebar i {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    margin-right: 20px;
    display: inline-block;
}

.page-sidebar svg {
    height: 24px;
    margin-left: -10px;
    margin-top: -2px;
    float: left;
    display: inline-block;
    width: 47px;
}

.page-sidebar .svg {
    width: 50px;
    font-size: 20px;
    line-height: 20px;
    display: inline-block;
    padding: 0;
    margin: -8px 7px 0 -15px;
    float: left;
}

.page-sidebar .sub-menu svg {
    height: 40px !important;
    margin-top: -10px !important;;
}

.page-sidebar .active,
.page-sidebar .active a,
.page-sidebar a:hover,
.page-sidebar .sub-menu li.active a,
.sub-menu a:hover{
	background-color: rgba(204,204,204,0.1);
}

.svg-icon {
    -webkit-transform: scale(3.5);
    -moz-transform: scale(3.5);
    -ms-transform: scale(3.5);
    -o-transform: scale(3.5);
    transform: scale(3.5);
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713, SizingMethod="auto expand");
}

.svg-icon-small {
    margin-top: -3px;
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713, SizingMethod="auto expand");
}

.page-sidebar .arrow {
    float: right;
    width: 20px;
    height: 20px;
    margin-right: -10px;
    text-align: center;
}

.page-sidebar .arrow i {
    margin-right: 0px !important;
}

.sub-menu {
    display: none;
    padding: 0px !important;
}

.sub-menu a {
    padding-left: 70px;
    height: 60px;
    padding-top: 17px;
}

.sub-menu .card {
    float: left;
    margin-right: 10px;
    width: 40px;
}

.page-sidebar .title {
    font-weight: bold;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
}

.sub-menu .title {
    font-size: 12px;
    display: block;
    font-weight: 700 !important;
    margin-top: 2px;
    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sub-menu .status {
    display: block;
    font-size: 12px;
    margin-top: -5px;
    font-weight: 400 !important;
    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sub-menu .status, .sub-menu .title {
    line-height: 10px;
    text-transform: capitalize;
}

/*
 * CONTAINER AND TIPOGRAFY
 */
.page-header {
    margin: 0px;
    background-color: #fbfbfb;
    border-color: #E7E7E7;
    display: block;
}


.page-sub-header {
    margin: 0px;
    background: #fff;
}

.page-sub-header .btn {
    margin: 33px 0;
}

.page-sub-header h2 {
    margin: 40px 12px;
    font-size: 18px;
    color: #727980;
}

/*
 * PAGE-TITLE
 */

.page-title {
    padding: 25px 0 0 13px;
    height: 100px;
}

.page-title img {
    float: left;
    margin-right: 20px;
}

.page-title h1 {
    margin: 5px 0 0 0;
    padding: 0;
    font-size: 18px;
}

.page-title span {
    display: block;
    height: 20px;
    font-size: 12px;
    color: #727980;
}

.page-button, .page-profit {
    padding: 32px 15px 0 0;
}

.page-profit {
    padding: 0;
    margin: 27px 0 0 0;
    float: right;
}

.page-button a {
    height: 35px;
    padding-top: 8px;
    font-weight: 700;
}

.page-profit .title {
    font-size: 12px;
    display: block;
    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #727980;
}

.page-profit .value {
    font-size: 24px;
    display: block;
    margin-top: -5px;
    font-weight: 700;
}

/*
 * PAGE-MENU
 */
.page-menu {
    height: 60px;
    border-bottom: 1px solid #e7e7e7;
    background-color: #fff;
}

.page-menu-buttons {
    padding-top: 20px;
    margin-right: 15px;
    margin-bottom: 20px;
    overflow: hidden;
}

.page-menu-buttons a {
    width: 20px;
    height: 20px;
    font-size: 18px;
    margin-left: 20px;
    display: inline-block;
    text-align: center;
    color: #bcc1ca;
}

.page-menu-buttons svg {
    width: 45px;
    height: 45px;
    margin-left: -13px;
    display: inline-block;
    margin-top: -13px;
}

.page-menu-buttons a:first-child {
    margin-left: 0;
}

.page-menu-list > ul {
    list-style: none;
    margin: 18px 0 0 3px;
    padding: 0;
    height: 60px;
}

.page-menu-list > ul > li {
    display: inline-block;
    margin: 0px 10px;
    height: 60px;
}

.page-menu-list > ul > li > a {
    padding: 20px 10px;
    margin: 10px;
    text-transform: uppercase;
    font-size: 12px;
    text-decoration: none;
    font-weight: 700;
    color: #bcc1ca;
}

.page-menu-list .menu-more-list {
    display: none;
    position: absolute;
    min-width: 160px;
    right: 50px;
    top: 55px;
    padding: 20px;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    z-index: 1000;
    background: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
}

.page-menu-list > ul > li > a:hover {
    border-bottom: none;
    color: #727980;
}

.menu-more-list > a {
    display: block;
    padding: 10px;

}

#transfer-summary, 
#transfer-info {
    width: auto;
    height: auto;
    background: #fbfbfb;
    border: 1px solid #e7e7e7;
}

#transfer-info {
	margin-top: 20px;
    padding: 20px;
    font-size:12px;
}

#transfer-summary h3 {
    margin: 0;
    padding: 20px;
    text-transform: uppercase;
    border-bottom: 1px solid #e7e7e7;
}

.transfer-summary-images {
    padding: 10px;
}

.transfer-summary-images .svg {
    max-width: 100px;
    max-height: 70px;
    margin: 0 auto;
    display: block;
}

.transfer-summary-images .arrow {
    -ms-transform: rotate(270deg) scale(1.5); /* IE 9 */
    -webkit-transform: rotate(270deg) scale(1.5); /* Chrome, Safari, Opera */
    transform: rotate(270deg) scale(1.5);
}

.transfer-summary-images span {
    display: block;
    text-align: center;
}

.transfer-summary-footer div {
    border-top: 1px solid #e7e7e7;
    padding: 20px;
}

.transfer-summary-footer span {
    float: right;
    font-weight: 700;
    font-size: 14px;
}

#transfer-info span {
    font-weight: 700;
}

#summary-from-name, #summary-to-name {
    height: 20px;
    overflow: hidden;
    color: #3b4b60;
}

.transfer-steps {
    margin-top: 20px;
}

.digit:after {
    content: '-';
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    right: -3px;
    top: 8px;
}

.step {
    padding-bottom: 40px;
    margin: 0 0 0 30px;
    border-left: 1px solid #BDC1CA;
}

.step:last-child {
	margin-bottom: 20px;
    height: 0;
}

.step-title {
    display: inline-block;
    margin-left: 20px;
    font-size: 18px;
    margin-bottom: 47px;
    color: #3b4b60;
}

.step-number {
    margin-left: -20px;
    height: 40px;
    width: 40px;
    padding-top: 10px;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 20px;
    display: inline-block;
    color: #fff;
    background: #BDC1CA;
    text-align: center;
}


.payment-steps{
	margin-bottom: 50px;
}

/*
 * PAGE-CONTAINER
 */

.page-container {
    background-color: #fff;
}

.page-container-middle {
    margin: 40px 13px 0 13px
}

.page-container-middle h2 {
    color: #727980;
}

.page-container-middle .row{
    margin:0;
}
.page-container-middle-invoices {
    margin-top:220px !important;
}

.page-container-invoices {
    margin-top:100px !important;
}

.page-container-recurrence {
    margin-top:200px !important;
}

.page-title .card {
    max-height: 50px;
    border-radius: 5px;
}

.page-admin {
    margin-top: 130px !important;
}

.page-admin .lineColor {
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;
    margin: 0 15px;
}

.page-admin .tab-content {
    padding: 30px 10px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.page-admin .nav-tabs a {
    background-color: #e7e7e7;
}

.page-admin .btn {
    /*margin: 15px 0;*/
}

.page-admin .btn-xs {
    margin: 0;
}

.page-cards {
    margin-top: 105px !important;
}

.page-cards .card {
    border: 1px solid #e7e7e7;
    height: 255px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 30px;
    background: #FBFBFB;
}

.page-cards .card-image {
    position: relative;
    padding: 35px 0;
    border-bottom: 1px solid #e7e7e7;
    background: #fff;
}

.page-cards .card-image img {
    margin: 0 auto;
    max-height: 120px;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
}

.card-options {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.85;
    background: rgba(129, 139, 155, 0.9);
    padding: 0 21px;
    z-index: 99;
}

.card-options .btn {
    margin-top: 80px;
    background-color: rgba(255, 255, 255, 0.3);
    height: 42px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 26px;
}

.card-options .icons{
	position: absolute;
	list-style: none;
	top: 0;
	right: 0;
	padding: 10px;
}

.card-options .icons li{
	display: inline-block;
	padding: 0 6px;
}

.card-options .icons li a{
	color: #fff;
	opacity: 0.7;
}

.card-options .icons li a:hover{
	opacity: 1;
}	

.card-options .icons .glyphicon{
	font-size: 18px;
}

.page-cards .card-detail {
    padding: 16px 10px;
    clear: both;
}

.page-cards .card-detail>div:first-child {
    width: 40%;
    float: left;
}

.page-cards .card-detail div.balance-detail {
    width: 60%;
    float: right;
    text-align: right;
}

.card-detail span {
    display: block;
    text-transform: capitalize;
}

.card-detail .card-name {
    font-size: 14px;
    line-height: 14px;
    height: 16px;
    overflow: hidden;
    font-weight: 700;
    text-transform: capitalize;
}

.card-detail .card-product {
    font-size: 11px;
    height: 40px;
    overflow: hidden;
}

.card-balance-title {
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    overflow: hidden;
}

.card-balance-value {
    font-size: 14px;
    margin-top: 0px;
    font-weight: 700;
    line-height: 12px;
}

.page-container-top {
    height: 40px;
    margin-left: 13px;
}

.page-container-top .filter {
    padding-right: 29px;
    position: relative;
}

.page-container-top .filter a, 
.page-container-top .menu-flutuante > button {
    width: 40px;
    height: 40px;
    margin-left: 5px;
    font-size: 18px;
    line-height: 14px;
    text-align: center;
    padding-top: 10px;
    color: #bcc1ca;
}

.page-container-top .filter a:first-child {
    margin-left: 0;
}

.page-container-top .filter a:hover{
	color: #808E9B;
    fill: #808E9B;
}

#filter-all, #filter-nothing{
    font-size: 14px;
    text-decoration: underline;
    margin-top:5px;
}

.filter-dropdown {
    display: none;
    width: 248px;
    height: 355px;
    position: absolute;
    z-index: 1000;
    margin-right: 85px;
}

.filter-dropdown > h3 {
    border-bottom: 1px solid #e7e7e7;
    padding: 12px 20px 18px 20px;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #727980;
}

.filter-dropdown .checkbox {
    margin-left: 20px;
    margin-bottom: 15px;
}

.filter-dropdown #categories {
    overflow: auto;
    height: 302px;
    font-size: 14px !important;
}
/*
 Card Status
 */
.card-blocked:after, .card-lost:after, .card-pend:after {
    content: " ";
    background: url("/assets/img/cards/status/blocked.png") no-repeat center;
    background-size: contain;
    top: auto;
    bottom: 0px;
    position: absolute;
    right: auto;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 97;
}
.card-pend:after {
    background-image: url("/assets/img/cards/status/pending.png");
}
.card-lost:after {
    background-image: url("/assets/img/cards/status/canceled.png");
}
.card-status {
    left: 0;
    position: relative;
    top: 0;
}
.page-title .card-status{
    position:static;
}
.page-title .card-status:after{
    margin-left:-9px;
}
.page-title .card-text{
    margin-left: 100px;
}
.page-title .card-image{
    position:absolute;
    content: '';
}
/*
 Menu Flutuante
 */
.menu-flutuante {
    margin-bottom: 20px;
}

.menu-flutuante label {
    width: 25px;
    text-align: right;
}

.menu-flutuante select {
    width: 90px !important;
}

.menu-flutuante i {
    margin-top: -10px;
}

.menu-flutuante .form-inline {
    margin-bottom: 15px;
}

.menu-flutuante-dropdown {
    display: none;
    width: 190px;
    height: 160px;
    position: absolute;
    z-index: 1000;
    left: 50%;
    margin-left: -95px;
    background-color: #fff;
}

#menu-flutuante-period-custom {
    display: none;
    padding: 0 15px;
    margin-top: 16px;
}

.menu-flutuante ul {
    list-style: none;
    margin: 13px 0 0 0;
    padding: 0;
    text-align: center;
}

.menu-flutuante li, 
.menu-flutuante a {
    display: block;
    height: 30px;
    color: #727980;
}

.menu-flutuante a {
    padding: 10px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 10px;
    text-decoration: none;
}

.menu-flutuante a:hover {
    background-color: #fbfbfb;
}

.menu-flutuante button {
    margin: 0 !important;
}

.menu-flutuante .last {
    margin-left: -6px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.menu-flutuante .first {
    margin-right: -3px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.menu-flutuante-date {
    width: 260px;
    font-family: "Lato", sans-serif;
    box-shadow: none;
    font-size: 12px;
    height: 40px;
    vertical-align: top;
    display: inline-block;
    text-transform: uppercase;
    padding-top: 10px;
    border-radius: 0;
    position: relative;
    background: none repeat scroll 0% 0% #FFF;
    border: 1px solid #E7E7E7;
    color: #bcc1ca;
}

.menu-flutuante-date:before {
    position: absolute;
    font-family: FontAwesome;
    top: 7px;
    right: 10px;
    font-size: 18px;
    content: "\f13a";
}

.menu-flutuante-date:hover, .menu-flutuante-date:focus, .menu-flutuante-date:active {
    position: relative;
    z-index: 20;
}

.filter .input-search .input-search-h {
    width: 230px;
    float: right;
    height: 40px;
    display: none;
}

/** START CLEAR SEARCH **/

.center-block {
    float: none;
    margin-left: auto;
    margin-right: auto;
}

.input-group .icon-addon .form-control {
    border-radius: 0;
}

.icon-addon {
    position: relative;
    color: #555;
    display: block;
}

.icon-addon:after,
.icon-addon:before {
    display: table;
    content: " ";
}

.icon-addon:after {
    clear: both;
}

.icon-addon.addon-md .glyphicon,
.icon-addon .glyphicon,
.icon-addon.addon-md .fa,
.icon-addon .fa {
    position: absolute;
    z-index: 2;
    left: 10px;
    font-size: 14px;
    width: 20px;
    margin-left: -2.5px;
    text-align: center;
    padding: 10px 0;
    top: 1px
}

.icon-addon.addon-lg .form-control {
    line-height: 1.33;
    height: 46px;
    font-size: 18px;
    padding: 10px 16px 10px 40px;
}

.icon-addon.addon-sm .form-control {
    height: 30px;
    padding: 5px 10px 5px 28px;
    font-size: 12px;
    line-height: 1.5;
}

.input-search-h{
    height: 40px !important;
}

.btn-send-transfer-email{
    height: 40px !important;
}

.btn-clear-search{
    border: none;
    outline:none;
    background:none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: none;
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 0px 0px;
}

.btn-clear-search:active
.btn-clear-search:hover
.btn-clear-search:focus{
    border: none;
    outline:none;
    background:none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: none;
}

.btn-export{
    color: #90969c;
    width: 40px !important;
    margin-left: 20px;
}

.btn-export:active
.btn-export:hover
.btn-export:focus{
    color: #7b828a;
}

.icon-addon.addon-lg .fa,
.icon-addon.addon-lg .glyphicon {
    font-size: 18px;
    margin-left: 0;
    left: 11px;
    top: 4px;
}

.icon-addon.addon-md .form-control,
.icon-addon .form-control {
    padding-left: 30px;
    float: right;
    font-weight: normal;
}

.icon-addon.addon-sm .fa,
.icon-addon.addon-sm .glyphicon {
    margin-left: 0;
    font-size: 12px;
    left: 5px;
    top: -1px
}

.icon-addon .form-control:focus + .glyphicon,
.icon-addon:hover .glyphicon,
.icon-addon .form-control:focus + .fa,
.icon-addon:hover .fa {
    color: #2580db;
}

/** END CLEAR SEARCH **/

.page-container-top .title h2 {
    padding: 10px 0 0 0;
    margin: 0 0 20px 0;
    color: #727980;
}

/*
 * TABLE
 */
.table {
    font-size: 14px;
    line-height: 14px;
    border-style: solid;
    border-width: thin;
    border-color: #e7e7e7;
}

.table tr, .table thead {
    border-top: 1px solid #e7e7e7;
}

.table#cardStatement,
.table#accountStatement {
    font-size: 12px;
}

.table#cardStatement .tooltip-box,
.table#accountStatement .tooltip-box {
    font-size: 16px;
    cursor: pointer;
}

.table > thead > tr > th {
    text-transform: uppercase;
    border-bottom: 1px solid #e7e7e7;
    background-color: #f9f9f9;
    color: #C0C3CC;
}

.table td, .table th {
    padding: 14px 0 15px 0 !important;
}

.table td:first-child, .table th:first-child {
    padding-left: 40px !important;
}

.table td {
    height: 40px;
    color: #727980;
}

.table th {
    font-size: 12px;
}

.table-striped > tbody > tr:nth-child(2n) {
    background-color: #fbfbfb;
}

.one-row {
    text-align: center;
}

.one-row * {
    padding: 95px 0px;
    display: block;
}

/*
 * LOGIN
 */
body.login {
    background: #fbfbfb;
    padding-bottom: 60px;
}

div.login {
    width: 350px;
    position: absolute;
    left: 50%;
    margin-left: -175px;
    margin-top:20px;
}

.login-header {
    text-align: center;
}

.login-header h2 {
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    margin: 0 0 10px 0;
    padding: 0;
}

.login-header p {
    margin: 0;
    padding: 0;
}

.login-box {
    width: 350px;
    background-color: #fbfbfb;
    border: 1px solid #e7e7e7;
    /*height: 416px;*/
    margin: 25px auto;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.login .navbar-header {
    position: absolute;
    left: 50%;
    margin-left: -69px;
}

.login .navbar-brand {
    padding: 0;
}

.login-menu {
    border-bottom: 1px solid #e7e7e7;
    background: #fff;
}

.login-menu a {
    display: inline-block;
    width: 174px;
    border-bottom: 4px solid #fff;
    text-align: center;
    margin: 0;
    padding: 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    color: #727980;
}

.login-menu a:hover{
	color: #727980;
}

.login-menu a:last-child {
    margin-left: -4px;
}

.login-menu a:first-child {
    margin-left: -0 !important;
}
.login-menu .one-link{
    width:100%;
}

.login-body {
    background: #fff;
    padding: 20px 22px 10px 22px;
}

.login-body label {
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 12px;
    color: #727980;
}

.login-body .form-control {
    border: 1px solid #e7e7e7;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-bottom: 24px;
    height: 40px;
    padding: 10px 20px;
    color: #727980;
}

.login-body.activate .form-control {
    margin-bottom: 0;
}

.login-body .forgot a {
    font-size: 12px;
    text-decoration: none;
    color: #727980;
}

.login-footer {
    border-top: 1px solid #e7e7e7;
    height: 60px;
}

.login-footer a {
    display: inline-block;
    width: 174px;
    text-align: center;
    margin: 20px 0 0 0;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    color: #727980;
}

.login-footer a:last-child {
    margin-left: 0px;
}

.login-footer .one-link {
    width: 100%;
}

.login-footer .two-link {
    width: 50%;
    float: left;
}

.login-body .btn-success {
    font-weight: 700;
    height: 40px;
}

.footer {
    padding:5px 0;
    position:fixed;
    bottom: 0;
    width: 100%;
    background:#fff;
    font-size:12px;
}

#frm_card {
    display: none;
}

/*
 * SAVING
 */
.saving{
    /*min-height: 150px;*/
    border: 1px solid #ebebeb;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #fbfbfb;
    /*height: 230px; */
}
.saving .icons {
    list-style: outside none none;
    padding: 15px;
    position: absolute;
    right: 15px;
    top: 5px;
}
.saving .saving-description{
    color:#3b4b60;
    text-align: left;
    border-bottom: 1px solid #ebebeb;
    margin:0;
    padding: 15px;
}
.saving .alert{
    font-size:12px;
    padding: 5px;
    margin: 0;
}
.saving .balance {
    font-size: 24px;
    padding:0 0 5px 15px;
    margin-top:0;
}
.saving .balance-label{
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 0;
    display: block;
    text-align: left;
    padding-left:15px;
    padding-top:25px;
}

.saving .red {
    color:#c44d4d;
    margin-left:10px;
}

.saving .silver {
    color:#a7a7a7;
}

.saving-buttons {
    padding: 15px;
}
.saving-buttons-form{
    padding-bottom: 0 !important;
}

.saving p {
    text-align: left;
    font-size: 20px;
}
.saving-buttons .saving-btn {
    text-transform: uppercase;
    font-size: 10px;
    width: 47%;
    min-width: 10px!important;
    background-color:#fff;
    border:1px #e7e7e7 solid;
}

.saving-buttons .saving-btn:last-child {
    float: right;
}
.saving-action-input {
    height: auto;
    margin-bottom: 20px;
}

/*
 * TYPOGRAPHY
 */
h1 {
    font-size: 24px;
}

h2 {
    font-size: 18px;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 14px;
}

h5 {
    font-size: 12px;
}

h6 {
    font-size: 10px;
}

/*
 * RESPONSIVE
 */
@media only screen and (max-width: 781px) {
    .modal-dialog {
        float: left !important;
        margin-top: 50px;
    }

    .nav-select .dropdown-menu {
        top: auto !important;
        right: 15px !important;
        left: auto !important;
        width: 220px;
    }

    .nav-select .dropdown-menu > .active > a {
        background: #fff;
        color: #555555;
    }

    .nav-select {
        display: block;
    }

    .btn-nav-select {
        border-radius: 0;
        background-color: #fff;
        border: 1px solid #e7e7e7;
        border-left: 4px solid #0088cb;
    }

    .nav-select:after {
        content: '\f107';
        font-family: FontAwesome;
        font-weight: normal;
        font-style: normal;
        margin: 0 0 0 -20px;
        text-decoration: none;
        position: relative;
        top: -29px;
        left: 100%;
    }

    .modal-dialog .nav-tabs {
        display: none;
    }

    .modal-dialog .tab-content {
        width: 100% !important;
        float: none !important;
    }
}

@media only screen and (max-width: 767px) {

    .page-menu-list {
        text-align: center;
    }

    #paymentMethods .paymentMethod{
        width: 39px;
    }
    .g-recaptcha{
        transform:scale(0.77);
        transform-origin:0 0;
    }

	.page-button{
    	max-width:200px !important;
    	margin: auto;
    }
    
    .modal {
        padding: 0 !important;
    }

    .modal-dialog {
    	width: 100%;
        margin: 50px 0 0 0 !important;
        padding: 0;
    }

    .modal-dialog .tab-content {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .modal-dialog .tab-content .form-horizontal .form-group {
        margin: 0;
    }

    .tab-content h3 {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .card-block-status {
        margin-top: 10px;
        font-size: 14px;
    }

    .navbar-brand {
        padding: 10px 0 0 0;
    }

    .navbar-brand > img {
        display: inline;
    }

    .page-cards {
        margin-top: 0 !important;
    }

    .page-container-middle {
        margin: 0;
    }

    #menu-flutuante-period-custom select, #menu-flutuante-period-custom input {

        display: inline;
    }

    .modal-content {
        width: 100%;
        border: none;
        position: absolute;
        left: 0;
        padding-bottom: 10px;
    }

    .page-sidebar {
        display: none;
        z-index: 1040;
    }

    .container-fluid {
        padding: 0;
    }

    .profile-menu {
        width: 230px;
        height: 283px;
        color: #555;
        margin-right: 15px;
        top: 45px;
    }

    #profile-div {
        position: fixed;
    }

    .profile-avatar {
        padding-right: 0px;
    }

    .profile-text {
        margin-top: 0px;
        width: 135px;
    }

    .last-access {
        font-size: 10px;
        line-height: 14px;
    }

    .last-access strong {
        font-size: 12px;
        display: block;
    }

    .profile-buttons {
        width: 100%;
        padding: 20px;
    }

    .profile-buttons .btn {
        display: block;
        margin-top: 10px;
    }

    .profile-buttons a:first-child {
        margin: 0;
    }

    /*.page-header {
        height: 150px;
    }*/
    .page-menu {
        height: 40px;
    }

    .page-menu-list > ul {
        margin: 0;
        margin-top: 6px;
        height: 40px;
    }

    .page-menu-list > ul > li {
        height: 40px;
    }

    .page-menu-list > ul > li > a {
        height: 40px;
        padding: 10px 4px;
        margin: 0 10px;
    }

    .page-menu-list .menu-more-list {
        top: 30px;
        right: auto;
    }

    .page-profit {
        text-align: center;
        margin-top: 0 !important;
        float: none;
    }

    .page-profit, .page-button {
        padding: 0;
    }

    .table td:first-child, .table th:first-child {
        padding-left: 4px !important;
    }

    .modal-dialog {
        width: 100%;
    }

    .navbar-header {
        width: 100%;
        text-align: center !important;
    }

    .login .navbar-brand > img {
        display: inline;
    }

    .login .navbar-header {
        position: relative;
        left: auto;
        margin-left: 0;
    }

    .login .navbar-brand {
        width: 100%;
        position: relative;
        left: 0;
        margin-left: 0;
    }

    .navbar-brand {
        left: 50%;
        margin-left: -100px;
        position: absolute;
        text-align: center !important;
        width: 200px;
    }

    .navbar-brand a {
        display: inline;
    }

    .modal-basic .panel,
    #ModalCarga .panel {
        margin-top: 10px;
        margin-right: -15px;
    	margin-left: -15px;
    }

    .page-transfer {
        margin-top: 0 !important;
    }

    .step {
        padding-left: 50px;
    }

    .step-title {
        margin-left: 5px;
        margin-bottom: 20px;
    }

    .step .form-group {
        margin-right: 0;
    }

    .step-title .btn-success {
        margin-left: 50px;
    }

    .step-number {
        margin-left: -70px;
    }

    div.login {
        width: 99%;
        position: static;
        margin: 10px auto;
    }
    
    #paymentMethods .radio-inline span,
	#paymentMethods .radio-inline img,
	#paymentMethodsRecurrence .radio-inline span,
	#paymentMethodsRecurrence .radio-inline img{
		margin-left: 20px;
	}
}

@media only screen and (max-width: 440px) {
    .menu-flutuante .first, .menu-flutuante .last {
        width: 10% !important;
    }

    .menu-flutuante .menu-flutuante-date {
        width: 79% !important;
    }

    .page-menu-list > ul > li {
        margin: 0;
    }

    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .col-xs-split {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .col-xs-split-container {
        margin-top: 0px !important;
    }

    .page-menu-list > ul > li > a {
        margin: 3px !important;
    }

    .page-container-top .title h2 {
        font-size: 14px;
        margin-bottom: 25px;
    }

    .page-container-top .filter a {
        margin-left: 0px;
    }

    .page-menu-list .menu-more-list {
        top: 30px;
        right: 15px;
    }

    .profile-text {
        width: 125px;
    }

    .nav-xs-img {
        width: 125px;
    }

}

@media only screen and (min-width: 1400px) {
    .modal-dialog {
        float: right;
        margin: 50px -20px 0 0;
    }
}

@media (min-width: 767px) {
    /*
     * MODAL
     */
    .modal-dialog {
        float: right;
        margin: 50px -20px 0 0;
    }

    #ModalAddCard .modal-dialog,
    #ModalContestations .modal-dialog,
    #ModalPreferences .modal-dialog, 
    #ModalPreferencesCard .modal-dialog {
        width: 790px;
    }

    .modal-header {
        height: 70px;
    }
    
    .page-profit .title {
	 	max-width: 180px;
	}
	
	#paymentMethods .radio-inline,
	#paymentMethodsRecurrence .radio-inline{
	    padding-left: 40px;
	}
	
	.translate{
		margin-top: 20px;
	}
}

@media only screen and (max-width: 1030px) {
    /*.page-profit .value {
        font-size: 16px;
    }*/
    .page-profit {
        margin-top: 32px;
    }

    .menu-flutuante {
        width: 100%;
    }
}

@media only screen and (max-width: 1370px) {
    .filter .btn-search {
        margin: 0;
    }

    .filter .input-search{
        width: 50%; /* para navegadores que n�o suportam calc() */
        width: -webkit-calc(100% - 60px); /* para Chrome */
        width: -moz-calc(100% - 60px); /* para Firefox */
        width: calc(100% - 90px);
        float: right;
        height: 40px;
        display: none;
    }

}
@media only screen and (max-width: 800px) {
    .filter .btn-search {
        margin: 0;
    }

    .filter .input-search{
        width: 50%; /* para navegadores que n�o suportam calc() */
        width: -webkit-calc(100% - 60px); /* para Chrome */
        width: -moz-calc(100% - 60px); /* para Firefox */
        width: calc(100% - 135px);
        float: right;
        height: 40px;
        display: none;
    }

}

/* Bug Fix page-menu */
@media (max-width: 1356px) and (min-width: 1200px) {
    .page-menu-list > ul > li {
        margin: 0 !important;
    }
    .page-menu-list > ul > li > a {
        padding: 20px 7px;
    }
}

/* Bug charge-button */
@media (max-width: 952px) and (min-width: 768px), (max-width: 1168px) and (min-width: 1004px) {
    .page-button a {
        height: 35px;
        padding-top: 11px;
        font-weight: 700;
        font-size: 9px;
    }
}

@media only screen and (min-width: 1005px) {
    .page-header {
        position: fixed;
        width: 90%; /* para navegadores que n�o suportam calc() */
        width: -webkit-calc(100% - 250px); /* para Chrome */
        width: -moz-calc(100% - 250px); /* para Firefox */
        width: calc(100% - 250px);
        height: 100px;
        z-index: 1000;
    }

    .page-sub-header {
        position: fixed;
        width: 90%; /* para navegadores que n�o suportam calc() */
        width: -webkit-calc(100% - 250px); /* para Chrome */
        width: -moz-calc(100% - 250px); /* para Firefox */
        width: calc(100% - 250px);
        height: 100px;
        z-index: 1001;
        margin-top: 100px;
    }

    .page-menu {
        position: fixed;
        width: 100%;
        width: -webkit-calc(100% - 250px); /* para Chrome */
        width: -moz-calc(100% - 250px); /* para Firefox */
        width: calc(100% - 250px);
        height: 60px;
        margin-top: 100px;
        z-index: 998;
    }

    .page-container {
        position: fixed;
        width: 80%;
        width: -webkit-calc(100% - 250px); /* para Chrome*/
        width: -moz-calc(100% - 250px); /* para Firefox*/
        width: calc(100% - 250px);
        height: 120px;
        z-index: 997;
        margin-top: 160px;
        padding-top: 40px;
        /*-webkit-box-shadow: 0 15px 20px -20px rgba(0, 0, 0, 0.3);*/
        /*-moz-box-shadow: 0 15px 20px -20px rgba(0, 0, 0, 0.3);*/
        /*box-shadow: 0 15px 20px -20px rgba(0, 0, 0, 0.3);*/
    }

    .shadown-menu {
        -webkit-box-shadow: 0 15px 20px -20px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 15px 20px -20px rgba(0, 0, 0, 0.3);
        box-shadow: 0 15px 20px -20px rgba(0, 0, 0, 0.3);
    }

    .page-container-middle {
        margin-top: 280px;
    }
    
    .page-transfer {
        margin-top: 170px;
    }
}

/* Bug dashboard testeira */
@media (max-width: 1006px) and (min-width: 768px) {
    .page-cards {
        margin-top: 0 !important;
    }
}

/* Bug Fix menu-flutuante */
@media (max-width: 999px) and (min-width: 991px) {
    .menu-flutuante-date {
        width: 75%;
    }
}

/* Bug Fix menu-flutuante */
@media (max-width: 1006px) and (min-width: 992px) {
    .page-container-top .title {
        width: 100%;
    }
}

/* Bug Fix menu-flutuante */
@media only screen and (max-width: 1020px) {
    .page-container {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .page-menu-buttons {
        display: none;
    }
}

/*target Internet Explorer 9 and Internet Explorer 10:*/
@media screen and (min-width: 0\0
) {
    select {
        background: none;
        padding: 5px;
    }
}

/*target Internet Explorer 9 and Internet Explorer 10:*/
@media screen and (max-width: 350px) {
    div.login, .login-box {
        width: 100%;
    }

    .login-menu a, .login-footer a {
        width: 50%;
    }

}

/* Scrollbar */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 32px none #000000;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
    background: #808080;
}

::-webkit-scrollbar-track {
    background: #ffffff;
    border: 39px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: #c0c0c0;
}

::-webkit-scrollbar-track:active {
    background: #ffffff;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #bcc1ca;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #bcc1ca;
    opacity: 1;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #bcc1ca;
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #bcc1ca;
}


/* validate */

input.error,
select.error {
    border-color: #e03f3f !important;
    margin-bottom: 0 !important;
}

label.error {
    color: #e03f3f !important;
    margin-bottom: 0 !important;
    font-size: 10px;
}

/*
 * HELPERS
 */
.line-through {
    text-decoration: line-through;
}

.card-balance-ammount{
    font-size: 12px;
}

.card-balance-multimoeda-box .dropdown-toggle {
    border: 1px solid #caced5;
    border-radius: 0;
    width: 180px;
    text-align: left;
    padding: 10px;
}

.card-balance-multimoeda-box .dropdown-toggle img,
.card-balance-multimoeda-box .dropdown-toggle object,
.card-balance-multimoeda-box .dropdown-item img,
.card-balance-multimoeda-box .dropdown-item object {
    margin-right: 10px;
    height: 15px;
}

.card-balance-multimoeda-box .caret {
    float: right;
    margin-top: 6px;
}

.card-balance-multimoeda-box .dropdown-menu {
    padding: 5px;
    margin-top: 10px;
    border-radius: 0;
    width: 180px;
}

.card-balance-multimoeda-box .dropdown-toggle .card-balancemulti-value {
    float: left;
}

.card-balance-multimoeda-box .dropdown-header {
    font-size: 10px;
    padding: 10px 0 6px 20px;
}

.card-balance-multimoeda-box .dropdown-item {
    padding: 5px 0;
    padding: 4px 20px;
}

.card-detail .card-balancemulti-value span {
    display: inline;
}

.card-detail .dropdown-toggle {
    width: auto !important;
    border: none;
    background-color: transparent !important;
    padding: 0;
    float: right;
    font-size: 14px;
    margin-top: 0px;
    font-weight: 700;
    line-height: 12px;
}

.card-detail .dropdown-toggle:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.card-detail span.card-balancemulti-value {
    float: left;
    padding: 2px 4px;
}

.card-detail ul.card-balancemulti-value {
    top: 30px;
    right: -14px;
    width: auto;
}

.box-form {
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 2px;
}

.box-form form {
    margin: 20px 0;
    padding: 20px 40px;
}

.box-form .step-margin {
    margin-top: 60px;
}

.box-icon-success {
    font-size: 60px;
    color: #629d27;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #828b9c !important;
}

.tooltip .tooltip-inner {
    line-height: 12px !important;
}

.tooltip.top{
    margin-top: -6px !important;
}

.bg-white {
    background-color: #fff;
}
.box-form-split .radio-inline{
    margin: 0 10px;
}
.radio-split {
    padding: 0;
    margin-bottom: 20px;
}
.radio-split img {
    height: 35px;
}
.radio-split > div {
    padding:3px;
}
.radio-split select{
    background-color: #fff;
    margin-bottom:0 !important;
    text-align: center;
}
.split-payment-option {
    display:table-cell;
    text-align:center;
    vertical-align: middle;
    height: 93px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: 500px;
}
.split-payment-option.active {
    background-color:#f8f8f8;
    border-color: #0088cb;
    border-width: 2px;
}
#split-finish {
    margin-top: 50px;
}
.removeLinha{
	margin-top: 7px;
}

.span-print-tooltip{
    display: none;
}

.charge-success{
	text-align:center;
}

@media only print {
    .no-print {
        display: none;
    }

    .print{
        display: inline-block;
    }

    .container-fluid {
        padding: 0;
    }

    .span-print-tooltip{
        font-size: 12px;
    }

    .menu-flutuante-date{
        width: 260px;
        font-family: "Lato", sans-serif;
        box-shadow: none;
        font-size: 12px;
        height: 40px;
        vertical-align: top;
        display: inline-block;
        text-transform: uppercase;
        padding-top: 10px;
        border-radius: 0;
        position: relative;
        background: none repeat scroll 0% 0% #FFF;
        border: none;
        color: #bcc1ca;
    }

    .menu-flutuante-date::before{
        display: none;
    }

    .fa-arrow-down, .fa-arrow-up{
        display: inline-block !important;
    }

    .check-contestacoes .btn .row-fluid .page-header{
        display: none !important;
    }
}

.nav-max-height{
    max-height: 90px !important;
}

.nav-max-auto-height{
    max-height: 120px;
}

.split-word-break{
    word-break: break-all;
}
#charge-resume .row {
    border-bottom: 1px solid #eee;
    padding: 5px;
}
#charge-resume .row:last-child {
    border-bottom: none;
}
#charge-resume-additional .row {
    border-bottom: 1px solid #eee !important;
}
.dashboard-new-card {
    background-color: #f5f5f5;
    position: absolute;
    z-index: 99;
    font-weight: bold;
    margin-top:13px;
    padding:3px 10px;
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    -moz-border-radius-topright: 20px;
    -moz-border-radius-bottomright: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.dashboard-title svg {
    float: left;
    margin-right: 10px;
    margin-top: -4px;
}
.dropdown-menu-more:before {
    right: 75px;
}
.dropdown-menu-more:after {
    right: 76px;
}
.btn-wire-success{
    cursor: default !important;
}
.btn-wire-success, .btn-wire-success:hover, .btn-wire-success:focus, .btn-wire-success.focus, .btn-wire-success:active, .btn-wire-success.active, .btn-wire-success.disabled, .btn-wire-success:disabled, .btn-wire-success.disabled:hover, .btn-wire-success:disabled:hover {
    background: #609e27 !important;
    border: 1px solid #609e27 !important;
    cursor: default !important;
    font-family: 'Lato', sans-serif;
    color: #fff;
    text-transform: uppercase;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 12px;
}
.wire-transfer-body{
    margin-bottom: 4% !important;
}
.wire-transfer-head{
    margin-top: 4% !important;
}
.btn-sms-put-account{
    margin-top: 7px !important;
    margin-left: 16px !important;
}
.load-sms-put-account{
    margin-left: 50% !important;
}
.margin-send-sms-tkn{
    margin-top: 8px !important;
}